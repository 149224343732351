import type { SummaryModel } from "../../types"
import type { useAdherentOrdersReferential } from "../../useAdherentOrdersReferential"
import { OrderTable } from "../commons"
import { columns } from "../commons/columns"

type PastOrdersProps = {
  summary: SummaryModel
} & ReturnType<typeof useAdherentOrdersReferential> & {
    /** YYYY-MM-DD */
    startDateInclusive: string
    /** YYYY-MM-DD */
    endDateExclusive: string
  }

export const SpecificTimeRange = ({
  startDateInclusive,
  endDateExclusive,
  ...referentialProps
}: PastOrdersProps) => (
  <OrderTable
    queryConfig={{
      queryKey: ["offers", "my-orders"],
      resourceEndpoint: "/api/offers/my-orders",
      queryParams: { startDate: startDateInclusive, endDate: endDateExclusive },
    }}
    emptyMessage="Vous n'avez aucune commande passée"
    columns={[
      { renderDefinition: columns.dateAndNumber, width: "15%" },
      { renderDefinition: columns.laboratory, width: "20%" },
      { renderDefinition: columns.offer, width: "55%" },
      { renderDefinition: columns.total, width: "10%" },
    ]}
    {...referentialProps}
  />
)
