import {
  api,
  useMutation,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { useSignatoryEndpoints } from "../../useSignatoryEndpoints"
import type { FormValues } from "./schema"

export const useAddSignedDocumentApi = ({ templateId }: { templateId: string }) => {
  const toaster = useToaster()
  const queryClient = useQueryClient()
  const { endpoint } = useSignatoryEndpoints()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (values: FormValues) => {
      const pharmacyId = values.adherent.code
      const formData = new FormData()
      formData.append("file", values.document)

      await api.post(endpoint.addSignedDocument(templateId, pharmacyId), {
        body: formData,
      })
    },
    onSuccess(_res, values) {
      queryClient.invalidateQueries({
        queryKey: ["documents", "templates", templateId, "signatories"],
      })
      toaster.success("Ajout réussi")
    },
    onError: () => toaster.error("Une erreur est survenue"),
  })
  return { mutateAsync, isPending }
}
