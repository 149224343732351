import {
  type ReferentialEnum,
  referentialEnumToReferentialCodeList,
} from "@pharmupp/p3-front-commons"

export type NegotiationStatusName = keyof typeof negotiationStatusEnum
export const negotiationStatusEnum = {
  AGREEMENT_TERMINATED: { label: "Rupture d’accord" },
  PROSPECT: { label: "Prospect" },
  IN_PROGRESS: { label: "En cours" },
  UNSTABLE_TERMS: { label: "Tension avec le labo" },
  FINALIZED: { label: "Finalisé" },
  NOT_APPLICABLE: { label: "Néant" },
} as const satisfies ReferentialEnum
export const negotiationStatusReferential =
  referentialEnumToReferentialCodeList(negotiationStatusEnum)

export type PartnershipName = keyof typeof partnershipEnum
export const partnershipEnum = {
  NONE: { label: "Non partenaire" },
  CLASSIC: { label: "Classique" },
  PREMIUM: { label: "Premium" },
  PRIVILEGED: { label: "Privilégié" },
} as const satisfies ReferentialEnum
export const partnershipReferential =
  referentialEnumToReferentialCodeList(partnershipEnum)

export type CategoriesName = keyof typeof categoriesEnum
export const categoriesEnum = {
  ORTHOPEDICS: { label: "Orthopédie" },
  COMPRESSION: { label: "Compression" },
  NUTRITION: { label: "Nutrition" },
  INCONTINENCE: { label: "Incontinence" },
  GENERICS: { label: "Génériques" },
  BIOSIMILARS: { label: "Biosimilaires" },
  OTC_ADVICE: { label: "OTC / Conseil" },
  WHITE_PRODUCT_LINE: { label: "Gamme Blanche" },
  MOM_AND_BABY: { label: "Bébé & maman" },
  VACCINATION: { label: "Vaccination grippe" },
  VETERINARY: { label: "Vétérinaire" },
  DIABETES: { label: "Diabète" },
  DERMO_COSMETICS: { label: "Dermo-cosmétique" },
  NATURALNESS: { label: "Naturalité" },
  ONCOLOGY: { label: "Oncologie" },
  HOMECARE: { label: "MAD" },
} as const satisfies ReferentialEnum
export const categoriesReferential =
  referentialEnumToReferentialCodeList(categoriesEnum)

export type StatusesName = keyof typeof statusesEnum
export const statusesEnum = {
  ACTIVE: { label: "Actif", color: "success.main" },
  DEACTIVATED: { label: "Inactif", color: "error.main" },
} as const satisfies ReferentialEnum<{ color: string }>
export const statusesReferential = referentialEnumToReferentialCodeList(statusesEnum)
