import type { EmptyObject } from "type-fest"

export interface ReferentialCode<T extends string | number = string | number> {
  label: string
  code: T
}

/** @deprecated Please use ReferentialCode instead  */
export interface ReferentialValue<T extends string | number = string | number> {
  label: string
  value: T
}

/**
 * @description
 * For the Extra options, if you want an optional property, you need to add it like this.
 * Otherwise, you'll lose the optional property
 * @example
 * // Correct
 * { optional : string | undefined, color: string }
 * @example
 * // Wrong
 * { optional ?: string, color: string }
 */
export type ReferentialEnum<Extra extends Record<string, unknown> = EmptyObject> =
  Record<string, { label: string } & Extra>

export function referentialEnumToReferentialCodeList<
  T extends ReferentialEnum<Record<string, unknown>>,
>(enumObj: T) {
  return Object.entries(enumObj).map(([key, stat]) => ({
    code: key as keyof T,
    ...(stat as T[keyof T]),
  }))
}
