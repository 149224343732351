import { GlobalStyles } from "@mui/material"

export const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        // Avoid screen jumps caused by scrollbar appearence/desappearence when navigating
        overflowY: "scroll",
        // Update base font size used for rem unit
        fontSize: "17px",
      },
      body: {
        // Background
        backgroundColor: theme.palette.background.default,
        backgroundImage: "url(/cross-colored-faded.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom 0px left 0px",
        backgroundSize: "240px 240px",
      },
    })}
  />
)
