import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Stack, Typography, styled } from "@mui/material"
import {
  Dialog,
  FormFileSimpleField,
  FormSearchAsyncField,
  type ReferentialCode,
} from "@pharmupp/p3-front-commons"
import type { PropsWithChildren } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PharmacyOptionFormatter, type PharmacySearch } from "../../commons"
import { useSignatoryEndpoints } from "../../useSignatoryEndpoints"
import { type FormValues, schema } from "./schema"
import { useAddSignedDocumentApi } from "./useAddSignatoriesApi"

type AddSignedDocumentModalProps = {
  onClose: () => void
  templateId: string
  templateName: string
}

export const AddSignedDocumentModal = ({
  onClose,
  templateId,
  templateName,
}: AddSignedDocumentModalProps) => {
  const { endpoint } = useSignatoryEndpoints()
  const formApi = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const { mutateAsync } = useAddSignedDocumentApi({ templateId })

  const close = () => {
    onClose()
  }

  return (
    <Dialog
      open
      onClose={close}
      iconSvgSrc="/icons/add-signed-doc.svg"
      title="Ajouter un document signé"
      subtitle={templateName}
      cancelAction={close}
      validateAction={formApi.handleSubmit(
        function onValid(values) {
          return mutateAsync(values, {
            onSuccess: () => close(),
          })
        },
        function onError(errors) {
          console.error(errors)
        },
      )}
    >
      <FormProvider {...formApi}>
        <Box minWidth={500}>
          <Stack gap={6}>
            <Step index={1} title="Identifier la Pharmacie">
              <FormSearchAsyncField<ReferentialCode & PharmacySearch>
                name="adherent"
                label="Pharmacies"
                placeholder="Rechercher une pharmacie"
                endpoint={endpoint.listUnsigned(templateId)}
                optionLabelFormatter={(option) => (
                  <PharmacyOptionFormatter {...option} />
                )}
                required
              />
            </Step>

            <Step index={2} title="Insérer le document signé au format PDF">
              <FormFileSimpleField<FormValues>
                name="document"
                label="Document signé"
                placeholder="Sélectionner le document"
                accept="application/pdf"
                required
              />
            </Step>
          </Stack>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

const Step = ({
  index,
  title,
  children,
}: PropsWithChildren<{ index: number; title: string }>) => (
  <Stack gap={1.5}>
    <Stack direction="row" alignItems="center" gap={1}>
      <Circle>{index}</Circle>
      <Typography variant="16px" fontWeight={800}>
        {title}
      </Typography>
    </Stack>
    {children}
  </Stack>
)

const Circle = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  width: "33px",
  height: "33px",
  backgroundColor: theme.palette.primary.light1,
  color: "white",
  lineHeight: theme.typography.pxToRem(33),
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 800,
  textAlign: "center",
}))
