import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import {
  GoalTrackingContainer,
  GoalTrackingPlaceholder,
  OperationTrackingRow,
  ROLES,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { useMyBonusOperationsApi } from "./useMyBonusOperationsApi"

export interface MyBonusOperationsProps {
  operationLink?: (id: number) => string
  allOperationsLink: string
  year: number
}
export const MyBonusOperations = ({
  operationLink,
  allOperationsLink,
  year,
}: MyBonusOperationsProps) => {
  const isUppPresident = useRoleCheck(ROLES.UPP_PRESIDENT)
  const { operations, loading } = useMyBonusOperationsApi({ year })

  return (
    <Box width="100%">
      {loading || !operations ? (
        <CircularProgress sx={{ display: "block", m: "150px auto" }} />
      ) : (
        <GoalTrackingContainer>
          {operations.length > 0 ? (
            operations.map((operation) => (
              <OperationTrackingRow
                key={operation.bonusOperationId}
                title={operation.title}
                tag={operation.tag ? `#${operation.tag}` : undefined}
                {...(operationLink && {
                  link: operationLink(operation.bonusOperationId),
                })}
                currentValue={operation.justifiedAmount || 0}
                goal={operation.uppBonus || 0}
              />
            ))
          ) : (
            // EMPTY CASE
            <Stack gap={0.5}>
              <Typography ml={4} mb={2} variant="18px" color="common.electricBlue">
                Nouvelle année, nouveaux objectifs !
              </Typography>
              {isUppPresident ? (
                <GoalTrackingPlaceholder
                  title="Activez les opérations pour l’année à venir"
                  link={allOperationsLink}
                />
              ) : (
                <GoalTrackingPlaceholder title="Pas d’opération active pour le moment " />
              )}
            </Stack>
          )}
        </GoalTrackingContainer>
      )}
    </Box>
  )
}
