import { type MouseEventHandler, useState } from "react"
import { MoreVertical } from "react-feather"
import { Menu } from "../Menu"
import { ActionButton } from "./ActionButton"
import type { ActionButtonTriggerProps } from "./ActionButtonTrigger"

export const ActionButtonMenu = ({
  items,
  loading,
  IconProps,
}: ActionButtonTriggerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }
  const handleClose: MouseEventHandler = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }
  const menuProps = {
    anchorEl: anchorEl,
    open: !!anchorEl,
    onClose: handleClose,
    onClick: handleClose,
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    } as const,
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    } as const,
  }

  return (
    <>
      <ActionButton
        {...IconProps}
        loading={loading}
        Icon={MoreVertical}
        onClick={handleClick}
      />

      <Menu {...menuProps} items={items} />
    </>
  )
}
