import type { Item, MenuItemType, MenuProps } from "../Menu"
import { ActionButton, type ActionButtonProps } from "./ActionButton"
import { ActionButtonMenu } from "./ActionButtonMenu"

export type ActionButtonTriggerProps = {
  items: ActionButtonTriggerItem[]
} & Pick<ActionButtonProps, "IconProps" | "loading">

export const ActionButtonTrigger = ({
  items,
  loading,
  IconProps,
}: ActionButtonTriggerProps) => {
  const item = items.length === 1 && isItem(items[0]) ? items[0] : null
  if (item) {
    return (
      <ActionButton
        {...IconProps}
        loading={loading}
        Icon={item.Icon}
        onClick={() => item.onClick?.()}
      />
    )
  }
  return <ActionButtonMenu {...IconProps} items={items} loading={loading} />
}

const isItem = (item: MenuProps["items"][number]): item is Item => {
  return "Icon" in item
}

export type ActionButtonTriggerItem = MenuItemType
