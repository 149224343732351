import { useFileDownload } from "@pharmupp/p3-front-commons"
import { useSignatoryEndpoints } from "../../useSignatoryEndpoints"

interface UseDownloadDocument {
  templateId: string
  pharmacyId: string
}

export const useDownloadUnsignedDocument = ({
  templateId,
  pharmacyId,
}: UseDownloadDocument) => {
  const { endpoint } = useSignatoryEndpoints()
  const { startDownload, isDownloading } = useFileDownload({
    name: "unsigned-document",
    endpoint: endpoint.downloadSignatoryDocument(templateId, pharmacyId),
    extension: "pdf",
  })
  return {
    dlUnsignedDoc: startDownload,
    isDLUnsignedDoc: isDownloading,
  }
}

export const useDownloadSignedDocument = ({
  templateId,
  pharmacyId,
}: UseDownloadDocument) => {
  const { endpoint } = useSignatoryEndpoints()
  const { startDownload, isDownloading } = useFileDownload({
    name: "Document à signer",
    endpoint: endpoint.addSignedDocument(templateId, pharmacyId),
    extension: "pdf",
  })
  return {
    dlSignedDoc: startDownload,
    isDlSignedDoc: isDownloading,
  }
}
