import { ROLES, type RoleStrategy, useRoleConf } from "@pharmupp/p3-front-commons"

export interface EndpointApiRoleConf {
  deleteSignatoryDocument: (
    templateId: string,
    pharmacyId: string | number,
  ) => string
  postDocument: (templateId: string) => string
  downloadSignatoryDocument: (
    templateId: string,
    pharmacyId: string | number,
  ) => string
  getSignatories: (templateId: string) => string
  listUnsigned: (templateId: string) => string
  addSignedDocument: (templateId: string, pharmacyId: string | number) => string
}

export const apiRoleConf: RoleStrategy<EndpointApiRoleConf> = {
  [ROLES.ADMIN]: {
    deleteSignatoryDocument: (templateId, pharmacyId) =>
      `/api/documents/templates/${templateId}/signatories/${pharmacyId}`,
    postDocument: (templateId) =>
      `/api/documents/templates/${templateId}/signatories?commit=true`,
    downloadSignatoryDocument: (templateId, pharmacyId) =>
      `/api/documents/templates/${templateId}/signatories/${pharmacyId}/file/UNSIGNED_DOCUMENT`,
    getSignatories: (templateId) =>
      `/api/documents/templates/${templateId}/signatories`,
    listUnsigned: (templateId) =>
      `/api/documents/templates/${templateId}/non-signed`,
    addSignedDocument: (templateId, pharmacyId) =>
      `/api/documents/templates/${templateId}/signatories/${pharmacyId}/file/SIGNED_DOCUMENT`,
  },
}

export const useSignatoryEndpoints = () => {
  const endpoint = useRoleConf<EndpointApiRoleConf>(apiRoleConf)
  return { endpoint }
}
