import {
  Box,
  type BoxProps,
  Card,
  type CardProps,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material"
import { AmountChip, MarkdownDisplay } from "@pharmupp/p3-front-commons"
import type { ReactNode } from "react"
import { Check, HelpCircle } from "react-feather"

const CARD_HEIGHT = "83px"

type RatingCardProps = CardProps & {
  active?: boolean
  children: ReactNode
}
export const RatingCardLayout = ({
  active = false,
  sx,
  children,
}: RatingCardProps) => {
  const { palette } = useTheme()
  return (
    <Card
      component={Box}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      minHeight={CARD_HEIGHT}
      pl={3}
      pr={2}
      py={1}
      sx={{
        borderRadius: "8px",
        ...(active
          ? {
              border: `3px solid ${alpha(palette.loyalty.dark3, 0.3)}`,
            }
          : { border: "1px solid #e6e9ec" }),
        ...sx,
      }}
    >
      {children}
    </Card>
  )
}

export const RatingCardLabel = ({ children, ...props }: BoxProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    height="100%"
    {...props}
  >
    <Stack direction="row" alignItems="center" spacing={2}>
      {children}
    </Stack>
  </Box>
)

export const CardLabel = ({
  label,
  tooltip,
}: {
  label: string
  tooltip?: string | null
}) => {
  const { palette, typography } = useTheme()
  return (
    <Box
      component="div"
      sx={{
        display: "inline",
        "& *": {
          display: "inline",
        },
        "& p, & strong": {
          lineHeight: typography.pxToRem(17),
          color: palette.common.darkBlue,
        },
        "& svg": { verticalAlign: "-3px" },
      }}
    >
      <MarkdownDisplay>{label}</MarkdownDisplay>
      {!!tooltip && (
        <Tooltip title={tooltip}>
          <HelpCircle height={16} color={palette.grey.light} />
        </Tooltip>
      )}
    </Box>
  )
}

export const RatingCardValue = ({
  active,
  value,
  unit = " pts",
}: {
  active: boolean
  value: number
  unit?: string
}) => {
  const { palette } = useTheme()
  return (
    <AmountChip
      PrefixIcon={active ? Check : undefined}
      prefix={active ? "" : "+"}
      unit={unit}
      variant="outlined"
      color={active ? "white" : palette.loyalty.main}
      hasUnderline={!active}
      borderColor={active ? palette.loyalty.main : palette.loyalty.light2}
      sx={{ bgcolor: active ? palette.loyalty.main : "white" }}
    >
      {value}
    </AmountChip>
  )
}

export const RatingCardGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: theme.spacing(6),
  rowGap: theme.spacing(3),
}))

export const RatingCardQuantity = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme: { palette, typography }, active }) => ({
  fontSize: typography.pxToRem(21),
  fontWeight: 600,
  color: active ? palette.loyalty.main : palette.loyalty.main,
}))
