import { Box, Container, Modal, Stack, Typography, styled } from "@mui/material"
import { MyUnsignedDocsListInner } from "./MyUnsignedDocsList"

/**
 * It's a modal to avoid blocking the app rendering while fetching the blocking decision from backend
 */
export const MyUnsignedDocsBlockingScreen = () => (
  <Modal open disableAutoFocus>
    <FullScreenOverlay>
      {/* HEADER */}
      <Header>
        <Container>
          <Stack
            direction="row"
            gap={6}
            justifyContent="center"
            mx="auto"
            width="90%"
          >
            <Box>
              <img src="/illustration/sign-doc-critical.svg" alt="" />
            </Box>

            <Stack gap={2} justifyContent="center">
              <HeaderTitle role="heading">
                Vous avez des documents à signer
              </HeaderTitle>

              <HeaderText>
                Vous avez dépassé le délai requis pour la signature des documents,
                l’accès à votre espace adhérent est momentanément restreint.
              </HeaderText>

              <HeaderText>
                Signez les documents mis à votre disposition pour débloquer la
                situation
              </HeaderText>
            </Stack>
          </Stack>
        </Container>
      </Header>

      {/* CONTENT */}
      <Content>
        <MyUnsignedDocsListInner showBackLink={false} />
      </Content>
    </FullScreenOverlay>
  </Modal>
)

const FullScreenOverlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  inset: 0,
  overflow: "scroll",
  backgroundColor: theme.palette.background.default,
}))

const Header = styled("header")(({ theme }) => ({
  zIndex: 1,
  width: "100%",
  padding: theme.spacing(3, 4),
  background: "white",
  boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.32)",
}))

const HeaderTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography["21px"],
  fontWeight: 600,
  color: "#eb5735",
}))

const HeaderText = styled(Typography)(({ theme }) => ({
  ...theme.typography["16px"],
}))

const Content = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(4),
  // height: "100%",
  backgroundImage: "url(/cross-colored-faded.svg)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom 0px left 0px",
  backgroundSize: "240px 240px",
}))
