import { TableCell, TableRow, Typography } from "@mui/material"
import {
  ActionButtonTrigger,
  ButtonLink,
  ListItemState,
  PageHeader,
  PaperContent,
  ROLES,
  SortedTableCell,
  Table,
  useDialog,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { format, parse } from "date-fns"
import { Eye, MapPin, Trash, Video } from "react-feather"
import { useNavigate } from "react-router-dom"
import { FormNumberViews, ListChip, PostListFilterSection } from "../../core"
import { LocationType, rubricOptions } from "../model"
import { type ListEvent, useEventsListApi } from "./useEventsListApi"

export const EventsList = () => {
  const isAdmin = useRoleCheck(ROLES.ADMIN)
  const dialog = useDialog()

  // Routing
  const navigate = useNavigate()
  const handleDetail = (id: number) => navigate(`${id}`)

  // API
  const { listEndpoint, referential, getDeleteEndpoint } = useEventsListApi()

  return (
    <>
      {/* HEADER */}
      <PageHeader title="Liste des évènements">
        <ButtonLink variant="contained" to="create">
          Créer un évènement
        </ButtonLink>
      </PageHeader>

      {/* TABLE */}
      <PaperContent>
        <Table<ListEvent>
          queryConfig={{
            queryKey: ["news", "events", "list"],
            resourceEndpoint: listEndpoint,
            select: (list) =>
              list.map(
                ({
                  startDate,
                  publicationDate,
                  registrationDeadline,
                  ...event
                }) => ({
                  ...event,
                  startDate: format(new Date(startDate), "dd/MM/yyyy"),
                  publicationDate: formatDate(publicationDate),
                  registrationDeadline: formatDate(registrationDeadline),
                }),
              ),
          }}
          FilterSectionElement={
            <PostListFilterSection
              rubrics={rubricOptions}
              statuses={referential?.statuses || []}
            />
          }
          emptyMessage="Aucune actualité"
          renderHeader={({ sortProps }) => (
            <TableRow>
              <SortedTableCell {...sortProps} columnName="startDate" width="10%">
                Date
              </SortedTableCell>
              <SortedTableCell {...sortProps} columnName="title" width="23%">
                Titre
              </SortedTableCell>
              <SortedTableCell {...sortProps} columnName="rubric" width="12%">
                Rubrique
              </SortedTableCell>
              <SortedTableCell {...sortProps} columnName="views" width="7%">
                Vues
              </SortedTableCell>
              <SortedTableCell
                {...sortProps}
                columnName="locationType"
                align="center"
                width="9%"
              >
                Mode
              </SortedTableCell>
              <SortedTableCell
                {...sortProps}
                columnName="registrationDeadline"
                width="9%"
              >
                Limite inscription
              </SortedTableCell>
              <SortedTableCell
                {...sortProps}
                columnName="registeredCount"
                width="8%"
              >
                Nb inscrits
              </SortedTableCell>
              <TableCell width="14%">Statut</TableCell>
              <TableCell width="8%" />
            </TableRow>
          )}
          renderRow={({ row, api }) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              onClick={(e) => {
                e.preventDefault()
                handleDetail(row.id)
              }}
            >
              <TableCell>{row.startDate}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {!!isAdmin && row.creatorTypeEnum === "PRESIDENT_UPP" && (
                  <ListChip label="INFO PRÉSIDENT" fontColor="primary.main" />
                )}
                <Typography variant="12px">{row.title}</Typography>
              </TableCell>
              <TableCell>{row.rubric}</TableCell>
              <TableCell>
                <FormNumberViews views={row.views || 0} variant="compact" />
              </TableCell>
              <TableCell align="center">
                {LocationTypeIconMap[row.locationType]}
              </TableCell>
              <TableCell>{row.registrationDeadline}</TableCell>
              <TableCell align="center">
                {Number.isInteger(row.maximumGuests)
                  ? `${row.registeredCount}/${row.maximumGuests}`
                  : row.registeredCount}
              </TableCell>
              <TableCell>
                <ListItemState
                  value={row.status}
                  options={
                    referential?.statuses?.map((status) => ({
                      ...status,
                      value: status.code,
                    })) || []
                  }
                />
              </TableCell>
              <TableCell align="right">
                <ActionButtonTrigger
                  IconProps={{ edge: "end" }}
                  items={[
                    {
                      Icon: Eye,
                      label: "Consulter",
                      onClick: () => handleDetail(row.id),
                    },
                    {
                      Icon: Trash,
                      label: "Supprimer",
                      onClick: () => {
                        dialog.delete({
                          endpoint: getDeleteEndpoint(row.id),
                          onSuccess: api.refresh,
                        })
                      },
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          )}
        />
      </PaperContent>
    </>
  )
}

const formatDate = (date: string) =>
  format(parse(date, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")

const LocationTypeIconMap = {
  [LocationType.IRL]: <MapPin />,
  [LocationType.REMOTE]: <Video />,
}
