import { useDialog, useQueryClient, useToaster } from "@pharmupp/p3-front-commons"
import { useSignatoryEndpoints } from "../../useSignatoryEndpoints"

export const useDeleteDocument = ({
  templateId,
  pharmacyId,
}: { templateId: string; pharmacyId: string }) => {
  const dialog = useDialog()
  const toaster = useToaster()
  const queryClient = useQueryClient()
  const { endpoint } = useSignatoryEndpoints()

  const deleteDocument = () => {
    dialog.delete({
      endpoint: endpoint.deleteSignatoryDocument(templateId, pharmacyId),
      onSuccess: () => {
        toaster.success("Suppression réussie")
        queryClient.invalidateQueries({
          queryKey: ["documents", "templates", templateId, "signatories"],
        })
      },
      onError: () => toaster.error("Une erreur est survenue"),
    })
  }
  return { deleteDocument }
}
