import { Typography, useTheme } from "@mui/material"
import { Alert, BellWithCircleIcon } from "@pharmupp/p3-front-commons"

const lastYearMessage = (
  <>
    <Typography fontWeight="bold" display="inline" component="span">
      Clôture de fin d’année :
    </Typography>{" "}
    les données sont en cours de consolidation. Mise à jour très prochainement !
  </>
)

const getNextYearMessage = (year: number) => (
  <>
    <Typography fontWeight="bold" display="inline" component="span">
      Nouvelle année :
    </Typography>{" "}
    les données sont progressivement mises à jour d’ici fin février {year}, un peu de
    patience...
  </>
)

const currentDate = new Date()

export const getAlertMessage = (today: Date, year: number) => {
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() // careful, it's 0-based

  if (currentMonth === 0) {
    // january
    if (year === currentYear - 1) {
      return lastYearMessage
    }
    if (year === currentYear) {
      return getNextYearMessage(year)
    }
  } else if (currentMonth === 1) {
    // february
    if (year === currentYear - 1) {
      return undefined
    }
    if (year === currentYear) {
      return getNextYearMessage(year)
    }
  } else if (currentMonth === 11) {
    // december
    if (year === currentYear) {
      return lastYearMessage
    }
    if (year === currentYear + 1) {
      return getNextYearMessage(year)
    }
  }

  return undefined
}
export const LoyaltyCategoryAlert = ({ year }: { year: number }) => {
  const { typography } = useTheme()

  const message = getAlertMessage(currentDate, year)

  return (
    <>
      {!!message && (
        <Alert
          severity="warning"
          icon={
            <BellWithCircleIcon
              width={typography.pxToRem(35)}
              height={typography.pxToRem(35)}
            />
          }
          variant="standard"
          sx={{
            mt: 3,
            color: "#000",
            "&.MuiPaper-root": {
              backgroundColor: "#fce2d0",
            },
            "& .MuiAlert-message, & .MuiAlert-icon": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Typography fontSize={typography.pxToRem(16)}>{message}</Typography>
        </Alert>
      )}
    </>
  )
}
