import { LoadingButton } from "@mui/lab"
import {
  type IconButtonProps,
  IconButton as MuiIconButton,
  styled,
} from "@mui/material"
import type { MouseEventHandler } from "react"
import type { Icon as FeatherIcon } from "react-feather"

export interface ActionButtonProps {
  Icon: FeatherIcon
  onClick: MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  IconProps?: Omit<IconButtonProps, "color">
}

export const ActionButton = ({
  Icon,
  IconProps,
  loading = false,
  onClick,
}: ActionButtonProps) => {
  return (
    <IconButton
      {...IconProps}
      component={LoadingButton}
      loading={loading}
      onMouseDown={stopPropagation}
      onClick={onClick}
      data-testid="actions-button"
    >
      <Icon style={{ opacity: loading ? 0 : 1 }} />
    </IconButton>
  )
}

const stopPropagation: MouseEventHandler = (e) => e.stopPropagation()

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.grey.light,
  },
})) as typeof MuiIconButton
