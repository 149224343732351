import { ROLES } from "../hooks"
import { createJwt } from "../utils/test.helpers"
import type { PharmUPP_KeycloackInstance } from "./types"

/**
 * Mock Keycloak to avoid to be redirected to Keycloak auth page
 */
export const getKeycloackMock = (
  mockConf: Partial<PharmUPP_KeycloackInstance> & { tokenParsed?: any } = {},
) => {
  const tokenParsed = {
    name: "Jean Mock",
    given_name: "Jean",
    family_name: "Mock",
    // TODO: override this using .env.local file to avoid to commit those changes
    // Change email to activate mocks for special scenarios
    // email: "docs-to-sign@test.com",
    // email: "adh-critical-unsigned-docs@test.com",
    realm_access: {
      // Change role here
      roles: [
        ROLES.ADMIN,
        // ROLES.UPP_PRESIDENT,
        // ROLES.ADHERENT_HOLDER,
        // ROLES.ADHERENT_TEAM,
        // ROLES.ADHERENT_ADVISOR,
        // ROLES.EXTERN_CONTACT_LABORATORY,
      ],
    },
    ...mockConf?.tokenParsed,
  }
  const fakeToken = createJwt(tokenParsed)

  return {
    init: () => Promise.resolve(true),
    token: fakeToken,
    updateToken: () => Promise.resolve(fakeToken),
    authenticated: true,
    ...mockConf,
    tokenParsed,
  } as PharmUPP_KeycloackInstance
}
