import {
  api,
  useMutation,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { useSignatoryEndpoints } from "../useSignatoryEndpoints"
import type { FormValues } from "./schema"

export const useAddSignatoriesApi = ({ templateId }: { templateId: string }) => {
  const toaster = useToaster()
  const queryClient = useQueryClient()
  const { endpoint } = useSignatoryEndpoints()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (values: FormValues) => {
      const selectedSignatories = "SOME"
      const pharmacyIds = values.adherents.map((adh) => adh.code)

      await api.post(endpoint.postDocument(templateId), {
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          selectedSignatories,
          pharmacyIds,
        }),
      })
    },
    onSuccess(_res, values) {
      queryClient.invalidateQueries({
        queryKey: ["documents", "templates", templateId, "signatories"],
      })
      toaster.success(
        values.adherents.length > 1
          ? "Inscriptions réussies"
          : "Inscription réussie",
      )
    },
    onError: () => toaster.error("Une erreur est survenue"),
  })
  return { mutateAsync, isPending }
}
