import {
  api,
  useMutation,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { useSignatoryEndpoints } from "../../useSignatoryEndpoints"

export const useResendDocument = ({
  templateId,
  pharmacyId,
}: { templateId: string; pharmacyId: string }) => {
  const toaster = useToaster()
  const queryClient = useQueryClient()
  const queryKey = ["documents", "templates", templateId, "signatories"]
  const { endpoint } = useSignatoryEndpoints()

  const { mutateAsync: resend } = useMutation({
    mutationFn: async () => {
      await api.delete(endpoint.deleteSignatoryDocument(templateId, pharmacyId))

      return await api.post(endpoint.postDocument(templateId), {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          selectedSignatories: "SOME",
          pharmacyIds: [pharmacyId],
        }),
      })
    },

    onSuccess: () => {
      toaster.success("Document renvoyé")
      queryClient.invalidateQueries({ queryKey })
    },
    onError: () => toaster.error("Une erreur est survenue"),
    onMutate: () => toaster.info("Mise à jour du document..."),
  })
  return { resend }
}
