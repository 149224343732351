import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "@mui/material"
import {
  Dialog,
  FormSearchAsyncField,
  type ReferentialCode,
} from "@pharmupp/p3-front-commons"
import { FormProvider, useForm } from "react-hook-form"
import { PharmacyOptionFormatter, type PharmacySearch } from "../commons"
import { useSignatoryEndpoints } from "../useSignatoryEndpoints"
import { type FormValues, schema } from "./schema"
import { useAddSignatoriesApi } from "./useAddSignatoriesApi"

interface AddSignatoryModalProps {
  onClose: () => void
  templateId: string
}

export const AddSignatoryModal = ({
  onClose,
  templateId,
}: AddSignatoryModalProps) => {
  const { endpoint } = useSignatoryEndpoints()
  const formApi = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const { mutateAsync, isPending } = useAddSignatoriesApi({ templateId })

  const close = () => {
    onClose()
    formApi.reset()
  }

  return (
    <Dialog
      open
      onClose={close}
      iconSvgSrc="/icons/circle-send.svg"
      title="Ajouter un destinataire"
      cancelAction={close}
      loading={isPending}
      validateAction={formApi.handleSubmit(function onValid(values) {
        return mutateAsync(values, {
          onSuccess: () => close(),
        })
      })}
    >
      <FormProvider {...formApi}>
        <Box minWidth={500}>
          <FormSearchAsyncField<ReferentialCode & PharmacySearch>
            name="adherents"
            label="Pharmacies"
            placeholder="Rechercher une pharmacie"
            endpoint={endpoint.listUnsigned(templateId)}
            queryParams={{ excludeSignatories: true }}
            multiple
            optionLabelFormatter={(option) => (
              <PharmacyOptionFormatter {...option} />
            )}
          />
        </Box>
      </FormProvider>
    </Dialog>
  )
}
