import { type Tab, formatIsoDate } from "@pharmupp/p3-front-commons"
import { add, startOfMonth, startOfYear, sub } from "date-fns"
import { NonValidatedOrders } from "../NonValidated"
import { SpecificTimeRange } from "./SpecificTimeRange"

const now = new Date()
const year = now.getFullYear()
const startOfThisYear = formatIsoDate(startOfYear(now))
const startOfNextYear = formatIsoDate(startOfYear(add(now, { years: 1 })))
const startOfLastYear = formatIsoDate(startOfYear(sub(now, { years: 1 })))
const startOfTwoYearsAgo = formatIsoDate(startOfYear(sub(now, { years: 2 })))
const startOfThreeYearsAgo = formatIsoDate(startOfYear(sub(now, { years: 3 })))
const startOfThreeMonthsAgo = formatIsoDate(startOfMonth(sub(now, { months: 3 })))
const startOfNextMonth = formatIsoDate(add(startOfMonth(now), { months: 1 }))

export const specificTimeRangeTabs: Tab[] = [
  {
    Element: (props) => <NonValidatedOrders {...props} />,
    label: "Commandes en cours",
    to: "en-cours",
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDateInclusive={startOfThreeMonthsAgo}
        endDateExclusive={startOfNextMonth}
      />
    ),
    label: "3 derniers mois",
    to: "latest",
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDateInclusive={startOfThisYear}
        endDateExclusive={startOfNextYear}
      />
    ),
    label: year,
    to: year.toString(),
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDateInclusive={startOfLastYear}
        endDateExclusive={startOfThisYear}
      />
    ),
    label: year - 1,
    to: (year - 1).toString(),
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDateInclusive={startOfTwoYearsAgo}
        endDateExclusive={startOfLastYear}
      />
    ),
    label: year - 2,
    to: (year - 2).toString(),
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDateInclusive={startOfThreeYearsAgo}
        endDateExclusive={startOfTwoYearsAgo}
      />
    ),
    label: year - 3,
    to: (year - 3).toString(),
  },
]
