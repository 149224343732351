import { ROLES, api, useQuery, useRoleCheck } from "@pharmupp/p3-front-commons"
import { MyUnsignedDocsBlockingScreen } from "./MyUnsignedDocsBlockingScreen"
import { MyUnsignedDocsModal } from "./MyUnsignedDocsModal"

export const MySigningModals = () => {
  const isAdhHolder = useRoleCheck(ROLES.ADHERENT_HOLDER)
  const isAdhTeam = useRoleCheck(ROLES.ADHERENT_TEAM)
  const isAdh = isAdhHolder || isAdhTeam

  const { data: isBlocked } = useQuery({
    queryKey: ["documents"],
    queryFn: () => api.get<boolean>("/api/documents/adherent/is-blocked"),
    initialData: false,
    enabled: isAdh,
  })

  if (!isAdh) return null

  if (isBlocked) {
    return <MyUnsignedDocsBlockingScreen />
  }

  // Only adh holder can sign documents
  if (isAdhHolder) return <MyUnsignedDocsModal />

  return null
}
