/** eg: 01.12.2014 */
export const formatDateWithDots = (date?: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("fr-CH")

/** eg: 01/12/2024 */
export const formatDateWithSlashes = (date?: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("fr-FR")

/** eg: 01-12-2024 */
export const formatDateWithCarets = (date?: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("es-CL")

/** eg: 2024-12-01 */
export const formatDateQueryParam = (date?: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("fr-CA")

/** eg: 2024-12-01 */
export const formatIsoDate = (date: string | Date) => {
  const parsedDate = parseStringOrDate(date)
  if (parsedDate.getHours() === 0) {
    // To avoid errors with losing a day because of the different timezones, set time to 10h
    parsedDate.setHours(10)
  }
  return parsedDate.toISOString().split("T")[0]
}

const parseStringOrDate = (date?: string | Date) => {
  switch (true) {
    case date === undefined:
      return new Date()
    case typeof date === "string":
      return new Date(date)
    default:
      return date
  }
}
