import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormControl as MuiFormControl,
  Radio,
  RadioGroup,
  type RadioGroupProps,
  styled,
} from "@mui/material"
import { Controller, type FieldPath, type FieldValues } from "react-hook-form"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"

/**
 * @deprecated // to be removed. Use code instead
 */
interface OldRadioProps {
  label: string
  value: string | number | boolean
}

interface NewRadioProps {
  label: string
  code: string | number | boolean
}

export type FormRadioLineProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  radios: (OldRadioProps | NewRadioProps)[]
  defaultValue?: NewRadioProps["code"]
  label?: string
  required?: boolean
} & RadioGroupProps

export const FormRadioLine = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  radios,
  defaultValue,
  required = false,
  ...props
}: FormRadioLineProps<TFieldValues>) => {
  const conf = useFieldRoleConf(name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControl
            error={!!error}
            variant="standard"
            sx={{ flexDirection: "row" }}
            required={required}
          >
            {!!label && <FormLabel id={`${name}-label`}>{label}</FormLabel>}
            <RadioGroup
              {...(label && { "aria-labelledby": `${name}-label` })}
              row
              {...props}
              {...field}
              onChange={(...args) => {
                props.onChange?.(...args)
                field.onChange(...args)
              }}
              {...conf}
              value={field.value}
            >
              {radios.map(({ label: radioLabel, ...radio }) => {
                const isOldRadio = (
                  radio: Omit<OldRadioProps | NewRadioProps, "label">,
                ): radio is Omit<OldRadioProps, "label"> => "value" in radio
                const value = isOldRadio(radio) ? radio.value : radio.code
                return (
                  <FormControlLabel
                    key={radioLabel}
                    label={radioLabel}
                    value={value}
                    control={
                      <Radio
                        color="success"
                        required={required}
                        disabled={!!conf.disabled}
                      />
                    }
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
          <FormHelperText error>{error?.message}</FormHelperText>
        </>
      )}
    />
  )
}

const FormControl = styled(MuiFormControl)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
})
