import {
  type ReferentialCode,
  type UploadedFile,
  api,
  useQuery,
  useSuspenseQuery,
} from "@pharmupp/p3-front-commons"
import type {
  BuyingConditionsApi,
  CustomerServiceContact,
  LaboratoryCrisisContact,
} from "../../common"
import type {
  NegotiationStatusName,
  PartnershipName,
} from "../../laboratoryReferential"
import type { CatalogType } from "../../model"

export const useAdherentLabDetails = ({ labId }: { labId: string }) => {
  // LAB DETAILS
  const { data: laboratory, isLoading } = useQuery({
    queryKey: ["laboratories", labId, "adherent", "details"],
    queryFn: () =>
      api.get<ApiAdherentLaboratoryDetails>(`/api/laboratories/adherent/${labId}`),
  })

  // Referential
  const { data: referential, isLoading: isLoadingReferential } = useQuery({
    queryKey: ["laboratories", "referential", "adherent", "details"],
    queryFn: () =>
      api.get<ApiAdhLabDetailsReferential>("/api/laboratories/referential/adherent"),
  })

  return { laboratory, referential, loading: isLoading || isLoadingReferential }
}

export const useAdherentLabDetailsV2 = ({ labId }: { labId: string }) => {
  // LAB DETAILS
  const { data: laboratory } = useSuspenseQuery({
    queryKey: ["laboratories", labId, "adherent", "details"],
    queryFn: () =>
      api.get<ApiAdherentLaboratoryDetails>(`/api/laboratories/adherent/${labId}`),
  })

  // Referential
  const { data: referential } = useSuspenseQuery({
    queryKey: ["laboratories", "referential", "adherent", "details"],
    queryFn: () =>
      api.get<ApiAdhLabDetailsReferential>("/api/laboratories/referential/adherent"),
  })

  return { laboratory, referential }
}

export interface ApiAdherentLaboratoryDetails {
  id: number
  name: string
  description?: string
  address: {
    line1: string
    line2?: string
    postalCode: string
    city: string
    phone: string
    fax?: string
    webSite: string
  }
  catalogType: CatalogType
  availableOffersCount: number
  logo?: UploadedFile
  categories?: string[]
  partnershipType: PartnershipName
  challengeType?: "SDAV" | "CATEGORY"
  advantages?: string[]
  documents: BuyingConditionsApi
  fieldTeamLink?: string
  ultraConditions?: string
  // Customer service tab
  customerServiceContacts?: CustomerServiceContact[]
  // Crisis contacts tab
  crisisContacts?: LaboratoryCrisisContact[]
  negotiationStatus: NegotiationStatusName
}

export interface ApiAdhLabDetailsReferential {
  categories: ReferentialCode<string>[]
  channels: ReferentialCode<keyof CatalogType>[]
  /** Feature postponed */
  // challengeTypes: ReferentialCode<string>[]
}
